import React, {Component} from 'react';
import GridItem from '../../vendor/components/Grid/GridItem';
import CustomFormInput from '../../core/components/form_data/custom_form_input';
import {Club, County, Event, EventLimitedOption, PaymentAdjustment} from '../../core/services/backend-client';
import {CountyAdminAccount, DistrictAdminAccount, Session, StateAdminAccount} from '../../core/components/app/types';
import NavPills from '../../core/components/NavPills/NavPills';
import {withSession} from '../../core/session_context';
import CardHeader from '../../vendor/components/Card/CardHeader';
import CardIcon from '../../vendor/components/Card/CardIcon';
import {
  Add,
  ArrowLeft,
  Assignment,
  CancelPresentation,
  Clear,
  CloudUpload,
  FileCopy, HelpOutline,
  Save,
  Warning
} from '@material-ui/icons';
import CardBody from '../../vendor/components/Card/CardBody';
import Card from '../../vendor/components/Card/Card';
import withStyles, {WithStyles} from '@material-ui/core/styles/withStyles';
import dashboardStyle from '../../core/components/dashboard/dashboardStyle';
import Spinner from '../../core/components/spinner';
import ImageFormInput from '../../core/components/form_data/image_form_input';
import GridContainer from '../../vendor/components/Grid/GridContainer';
import Badge from '../../vendor/components/Badge/Badge';
import DateTimeFormInput from '../../core/components/form_data/date_time_picker_form_input';
import SingleCheckbox from '../../core/components/form_data/single_checkbox';
import MultiSelect from '../../core/components/form_data/multi_select';
import InputAdornment from '@material-ui/core/InputAdornment';
import CustomSelectFormInput from '../../core/components/form_data/custom_select_form_input';
import {FileAttachmentsFormInput} from '../../core/components/file_attachments_editor';
import LoadingButton from '../../core/components/loading_button';
import {
  extractError,
  formatDateRange,
  pluralize,
  uniq,
  validateEmail,
  formatMoney,
  arCountyCostCenterCodes, arCountyDesignationNumOptions
} from '../../core/shared/shared_helpers';
import SnackbarContent from '../../vendor/components/Snackbar/SnackbarContent';
import {Link as RouterLink, withRouter} from 'react-router-dom';
import moment from 'moment';
import {CustomForm, CustomFormEditorInput, FormField} from '../../core/components/custom_form/custom_form_editor';
import {EditWaiversInput} from '../../enrollments/components/waivers/edit_waivers';
import Button from '../../vendor/components/CustomButtons/Button';
import {alertModal, confirmModal, HelpModal, runModalForm, runSweetAlert} from '../../core/components/Modal/Modal';
import SelectMultiple from '../../core/components/select_multiple';
import PaymentAdjustmentTable from '../../core/components/payment_adjustment_table';
import {allRecordBookTypes, recordBookTypesForState} from '../../record_books/record_book_types';
import CustomFroalaTextField from '../../core/components/form_data/custom_froala_text_field';
import DateRangeFormInput from '../../core/components/form_data/date_range_form_input';
import TimeRangeSelect from '../../core/components/form_data/time_range_select_new';
import {FormData, WithFormProp} from '../../core/components/form_data';
import TableEdit from '../../core/components/table_edit';
import Table from '../../vendor/components/Table/Table';
import {List, ListItemText, Tooltip} from '@material-ui/core';
import TableModule from "../../record_books/components/table_module";

interface Props extends WithStyles {
  session: Session,
  history: any
  match: { params: { eventId: string } },
  disableRegistration: boolean,
}

interface State {
  event: Event,
  counties: County[],
  clubs: Club[],
  region?: 'State-Wide' | 'County-Wide' | 'Multi-County' | 'Multi-Club',
  error?: string,
  regRequired: boolean,
  paymentAdjustments: PaymentAdjustment[],
  projectTypes: string[],
  costCenterCodeOptions: any[],
  designationNumOptions: any[],
}

const emptyEvent = {
  status: 'Draft', fileAttachments: [],
  participantRegFields: [], participantWaivers: [],
  volunteerRegFields: [], volunteerWaivers: [],
} as any;

class EventEditor extends Component<Props, State> {
  async componentDidMount(): Promise<void> {
    const {
          session: {
            backendClient, account, state, orgId, projectLeaderTypes,
            settings: {county: countySettings, state: stateSettings, enrollmentsEnabled}
          },
          match: {params: {eventId: eventIdStr}},
          disableRegistration
        } = this.props,
        stateId = (account as StateAdminAccount)?.stateId,
        isStateAdmin = account?.accountType === 'StateAdmin',
        districtId = (account as DistrictAdminAccount)?.districtId,
        countyId = (account as CountyAdminAccount)?.countyId,
        eventId = parseInt(eventIdStr),
        countyIds = countyId ? [countyId] :
            (districtId ? (await backendClient.listCounties({districtId})).map(c => c.id) : []),
        [event, counties, paymentAdjustments] = await Promise.all([
          isNaN(eventId) ? Promise.resolve({
            ...emptyEvent,
            countyIds,
            stateId,
            orgId,
            isCountywide: !!countyId,
            regRequired: stateSettings.enableEventRegistration && !disableRegistration,
            createdByAccountId: (account || {}).id,
            enrollmentRequired: enrollmentsEnabled && stateSettings.enableEventRegistration && !disableRegistration,
            emailPreferences: {contactEmails: {registrationSubmitted: [(account as CountyAdminAccount).contactEmail || account?.email]}}
          }) : backendClient.findEvent({id: eventId}),
          districtId ? backendClient.listCounties({districtId}) :
              (state?.id ? backendClient.listCounties({stateId: state.id})
                  : Promise.resolve([])),
          isNaN(eventId) ? Promise.resolve([])
              : backendClient.listEventPaymentAdjustments({eventId: eventId}).catch(() => [])
        ]),
        projectTypes = projectLeaderTypes ? projectLeaderTypes : uniq(allRecordBookTypes(
            await recordBookTypesForState(state?.id, orgId))
            .concat(stateSettings.enrollmentProjectTypes || [])
            .concat(countySettings.countyProjectTypes || []))
            .sort((a, b) => a.localeCompare(b));
    let clubs: any = [];
    if (event.countyIds?.length === 1) {
      clubs = await backendClient.listClubs({countyId: event.countyIds[0]});
    }
    const minAgeAsOf = event.minAgeAsOf ? event.minAgeAsOf : moment().month(stateSettings.ageAsOfMonth - 1).date(stateSettings.ageAsOfDay);
    const maxAgeAsOf = event.maxAgeAsOf ? event.maxAgeAsOf : moment().month(stateSettings.ageAsOfMonth - 1).date(stateSettings.ageAsOfDay);
    const properties = event.properties || {};
    const costCenterCodeOptions = arCountyCostCenterCodes(countyId, stateId);
    const costCenterCode = event?.properties?.costCenter
    // console.log("COST CENTER CODE", costCenterCode);
    const designationNumOptions: any = costCenterCode ? arCountyDesignationNumOptions(costCenterCode) : [];
    this.setState({
      event: {
        ...event, ...{minAgeAsOf, maxAgeAsOf}, properties: {
          // Move es237PrimaryType ti es237PrimaryTypes to allow multiple types to be selected
          ...event.properties,
          es237PrimaryTypes: (properties.es237PrimaryType && !properties.es237PrimaryTypes)
              ? [properties.es237PrimaryType] : properties.es237PrimaryTypes
        }
      }, counties, clubs, paymentAdjustments,
      region: event.isStatewide ? 'State-Wide'
          : (event.isCountywide ? ((isStateAdmin || event.countyIds?.length > 1) ? 'Multi-County' : 'County-Wide')
              : (event.clubIds?.length ? 'Multi-Club' : undefined)),
      projectTypes,
      costCenterCodeOptions,
      designationNumOptions
    });
  }

  validateEvent(): boolean {
    const {session: {projectLeaderTypes, settings}} = this.props;
    const {event, region} = this.state,
        requiredFields: Record<string, string> = {
          'name': 'Name', 'startsAt': 'Starts At',
          ...(event.isAllDay ? {} : {'endsAt': 'Ends At'}),
          ...((settings.requireEventIndex && event.regRequired) ? {'eventIndex': 'Event Index'} : {}),
          //...((!!event.fee && settings?.state?.stateId === 'US-AR' && !event?.properties?.costCenter) ? {'costCenter': 'Cost Center'} : {}),
          //...((!!event.fee && settings?.state?.stateId === 'US-AR' && event?.properties?.costCenter && !event?.properties?.designationNum) ? {'designationNum': 'Designation #'} : {}),

        },
        requiredErrors = Object.keys(requiredFields).map(f =>
            (event as any)[f] ? '' : requiredFields[f])
            .filter(x => !!x);

    if (requiredErrors.length) {
      this.setState({
        error: requiredErrors.join(', ') +
            (requiredErrors.length > 1 ? ' are' : ' is') + ' required'
      });
      return false;
    }

    if (projectLeaderTypes && !event?.projectTypes?.length) {
      this.setState({error: 'Must have at least 1 project type set'});
      return false;
    }

    if (!event.isAllDay && moment(event.startsAt).isAfter(moment(event.endsAt))) {
      this.setState({error: 'Ending time (Ends At) must be after starting time (Starts At)'});
      return false;
    }

    if (region === 'Multi-Club' && !event.clubIds?.length) {
      this.setState({error: 'Please select at least one club'});
      return false;
    }

    if (!region) {
      this.setState({error: 'Please select a Region'});
      return false;
    }

    const attendeeRegCap = parseInt(event.attendeeRegCap + '');
    if (event.attendeeRegCap && (isNaN(attendeeRegCap) || attendeeRegCap < 0)) {
      this.setState({error: 'Cap on # of Registrations cap must be a positive integer value'});
      return false;
    }

    const volunteerRegCap = parseInt(event.volunteerRegCap + '');
    if (event.volunteerRegCap && (isNaN(volunteerRegCap) || volunteerRegCap < 0)) {
      this.setState({error: 'Cap on # of Volunteers must be a positive integer value'});
      return false;
    }

    if (event.allowExternalRegistration && event.enrollmentRequired) {
      this.setState({error: 'Cannot require enrollment when allowing Non-ZSuite participants to register'});
      return false;
    }

    return true;
  }

  async saveEvent(status: string, stayOnPage: boolean) {
    const {session: {backendClient, account, projectLeaderTypes, state}, history, disableRegistration} = this.props,
        {event, paymentAdjustments, region} = this.state;

    if (status === 'Cancelled' && event.status !== 'Cancelled') {
      const confirm = await confirmModal('Are you sure?',
          'If you cancel this event this will notify anyone has has already registered.' +
          ' If you wish to only close registration you can do that by changing the open or close registration dates.')
          .then(() => true, () => false);
      if (!confirm) {
        return;
      }
    }

    let minGrade = event.minGrade, maxGrade = event.maxGrade;
    if (event.minGrade === 'Any') {
      minGrade = undefined as any;
    }
    if (event.maxGrade === 'Any') {
      maxGrade = undefined as any;
    }
    let isCountywide = region === 'County-Wide' || region === 'Multi-County',
        isStatewide = region === 'State-Wide';

    if (region === 'County-Wide') {
      if ((account as CountyAdminAccount)?.countyId) {
        event.countyIds = [(account as CountyAdminAccount).countyId];
        event.clubIds = [];
      } else {
        this.setState({error: 'No countyId on account# ' + account?.id});
        return;
      }
    }
    if (isStatewide) {
      event.countyIds = [];
      event.clubIds = [];
      event.stateId = (account as StateAdminAccount).stateId || (state?.id as string);
    }
    if (region === 'Multi-County' && !event.countyIds.length) {
      this.setState({error: 'You must select at least one county'});
      return;
    }
    if (region === 'Multi-County' && (account as CountyAdminAccount).countyId) {
      event.countyIds = uniq(event.countyIds.concat([
        (account as CountyAdminAccount).countyId
      ]));
    }

    const eventBody: Event = {
      ...event, status: status as any, minGrade, maxGrade,
      minAgeAsOf: moment(event.minAgeAsOf).format('YYYY-MM-DD'),
      maxAgeAsOf: moment(event.maxAgeAsOf).format('YYYY-MM-DD'),
      startsAt: moment.utc(event.startsAt).toISOString(),
      endsAt: moment.utc(event.endsAt).toISOString(),
      attendeeRegCap: parseInt(event.attendeeRegCap + ''),
      isCountywide, isStatewide
    };

    if (!this.validateEvent()) {
      return;
    }

    try {
      let eventId;
      if (event.id) {
        await backendClient.updateEvent({}, eventBody);
        eventId = event.id;
      } else {
        const newEvent = await backendClient.createEvent({}, eventBody);
        eventId = newEvent.id;
      }


      if (!disableRegistration) {
        // Remove deleted Payment Adjustments
        const currentAdjustments = await backendClient.listEventPaymentAdjustments({eventId});
        for (let paymentAdjustment of currentAdjustments) {
          if (!paymentAdjustments.find(a => a.id === paymentAdjustment.id)) {
            await backendClient.deletePaymentAdjustment({id: paymentAdjustment.id});
          }
        }
        // Save new/updated payment Adjustments
        for (let paymentAdjustment of paymentAdjustments) {
          if (!paymentAdjustment.id) {
            await backendClient.createPaymentAdjustmentForEvent({eventId}, paymentAdjustment);
          } else {
            await backendClient.updatePaymentAdjustment({id: paymentAdjustment.id}, paymentAdjustment);
          }
        }
      }

      stayOnPage ? history.push(`/${projectLeaderTypes ? 'project_leader/' : ''}events/${eventId}/edit`) : history.push(`/${projectLeaderTypes ? 'project_leader/' : ''}events`);

      if (event.allowExternalRegistration && status === 'Published') {
        await runSweetAlert({
          success: true, title: 'Successfully Published Event',
          body: `Registration Link for Non-ZSuite participants: https://4h.zsuite.org/external-event-registration/${eventId}`
        });
      }
    } catch (e) {
      console.error(e);
      this.setState({error: await extractError(e, 'Unable to save Event')});
    }
  }

  renderSaveButtons() {
    const {session: {settings}} = this.props;
    const {event, error} = this.state || {};
    const renderedError = error ? <SnackbarContent
        close onClose={() => this.setState({error: undefined})}
        icon={Warning} color="danger">{error}</SnackbarContent> : '';

    switch (event.status) {
      case 'Published':
        return <GridItem xs={12}>
          {renderedError}
          <LoadingButton color="danger" simple
                         onClick={this.saveEvent.bind(this, 'Cancelled')}>
            Cancel Event</LoadingButton>
          <LoadingButton color="warning" simple
                         onClick={this.saveEvent.bind(this, 'Closed')}>
            <CancelPresentation/> Close Event</LoadingButton>
          <LoadingButton color="primary" simple
                         onClick={this.saveEvent.bind(this, 'Draft')}>
            <CloudUpload/> Un-Publish Event</LoadingButton>
          <LoadingButton color="primary" simple
                         onClick={this.saveEvent.bind(this, 'Hidden')}>
            <CloudUpload/> Hide Event</LoadingButton>
          <LoadingButton color="primary" onClick={this.saveEvent.bind(this, event.status)}>
            <Save/> Update Event</LoadingButton>
          <LoadingButton color="primary" onClick={this.saveEvent.bind(this, event.status, true)}>
            <Save/> Save Changes (Stay in Event)</LoadingButton>
        </GridItem>;
      case 'Hidden':
        return <GridItem xs={12}>
          {renderedError}

          <LoadingButton color="danger" simple
                         onClick={this.saveEvent.bind(this, 'Cancelled')}>
            Cancel Event</LoadingButton>

          <LoadingButton color="warning" simple
                         onClick={this.saveEvent.bind(this, 'Closed')}>
            <CancelPresentation/> Close Event</LoadingButton>

          <LoadingButton color="primary" simple
                         onClick={this.saveEvent.bind(this, 'Draft')}>
            <CloudUpload/> Draft Event</LoadingButton>

          <LoadingButton color="primary" simple
                         onClick={this.saveEvent.bind(this, 'Published')}>
            <CloudUpload/> Publish Event</LoadingButton>

          <LoadingButton color="primary" onClick={this.saveEvent.bind(this, event.status)}>
            <Save/> Update Event</LoadingButton>

          <LoadingButton color="primary" onClick={this.saveEvent.bind(this, event.status, true)}>
            <Save/> Save Changes (Stay in Event)</LoadingButton>
        </GridItem>;


      case 'Cancelled':
        return <GridItem xs={12}>
          {renderedError}
          {!settings.hidePublishNewEvent ? <LoadingButton color="primary" simple
                                                          onClick={this.saveEvent.bind(this, 'Published')}>
            <CloudUpload/> Re-Publish Event</LoadingButton> : ''}
          <LoadingButton color="primary" onClick={this.saveEvent.bind(this, event.status)}>
            <Save/> Update Event</LoadingButton>

          <LoadingButton color="primary" onClick={this.saveEvent.bind(this, event.status, true)}>
            <Save/> Save Changes (Stay in Event)</LoadingButton>
        </GridItem>;
      case 'Closed':
        return <GridItem xs={12}>
          {renderedError}
          {!settings.hidePublishNewEvent ? <LoadingButton color="primary" simple
                                                          onClick={this.saveEvent.bind(this, 'Published')}>
            <CloudUpload/> Re-Publish Event</LoadingButton> : ''}
          <LoadingButton color="primary" onClick={this.saveEvent.bind(this, event.status)}>
            <Save/> Save</LoadingButton>

          <LoadingButton color="primary" onClick={this.saveEvent.bind(this, event.status, true)}>
            <Save/> Save Changes (Stay in Event)</LoadingButton>
        </GridItem>;
      default:
        return <GridItem xs={12}>
          {renderedError}
          {!settings.hidePublishNewEvent ?
              <LoadingButton color="primary" simple onClick={this.saveEvent.bind(this, 'Published')}>
                <CloudUpload/> Publish Event</LoadingButton> : ''}

          <LoadingButton color="primary" onClick={this.saveEvent.bind(this, 'Hidden')}>
            <Save/> Save as Hidden</LoadingButton>

          <LoadingButton color="primary" onClick={this.saveEvent.bind(this, 'Draft')}>
            <Save/> Save as Draft</LoadingButton>

          <LoadingButton color="primary" onClick={this.saveEvent.bind(this, 'Draft', true)}>
            <Save/> Save Changes (Stay in Event)</LoadingButton>
        </GridItem>;

    }
  }

  onChange(newEvent: Event) {
    if ((newEvent.startsAt && !newEvent.endsAt) ||
        moment(newEvent.startsAt).isAfter(moment(newEvent.endsAt))) {
      newEvent = {...newEvent, endsAt: moment(newEvent.startsAt).add(1, 'hour').toISOString()};
    }
    let designationNumOptions: any[] = [];
    if (newEvent?.properties?.costCenter) {
      designationNumOptions = arCountyDesignationNumOptions(newEvent?.properties?.costCenter);
    }
    this.setState(s => ({
      event: {...s.event, ...newEvent},
      designationNumOptions
    }));
  }

  async findClubs(countyIds: number[]) {
    const {session: {backendClient}} = this.props,
        {event} = this.state || {};
    let clubs: Club[] = [];
    for (const countyId of countyIds) {
      const countyClubs = await backendClient.listClubs({countyId});
      if (clubs.length === 0) {
        clubs = countyClubs;
      } else {
        clubs.push(...countyClubs);
      }
    }
    this.setState({event: {...event, countyIds}, clubs});
  }

  addEmailToPreference(email: string, preference: string) {
    const {event} = this.state || {};
    this.setState({
      event: {
        ...event,
        emailPreferences: {
          ...event.emailPreferences,
          contactEmails: {
            ...event.emailPreferences?.contactEmails,
            [preference]: (event.emailPreferences?.contactEmails?.[preference] || [])
                .concat([email])
          }
        }
      }
    });
  }

  removeEmailFromPreference(email: string, preference: string) {
    const {event} = this.state || {};
    this.setState({
      event: {
        ...event,
        emailPreferences: {
          ...event.emailPreferences,
          contactEmails: {
            ...event.emailPreferences?.contactEmails,
            [preference]: (event.emailPreferences?.contactEmails?.[preference] || [])
                .filter(approval => approval !== email)
          }
        }
      }
    });
  }

  saveEmailMessageRow(row: { type: string, message: string }) {
    const {event} = this.state || {};
    const [memberType, emailType] = row.type.split('+');
    this.setState({
      event: {
        ...event,
        emailPreferences: {
          ...event.emailPreferences,
          messages: {
            ...event.emailPreferences?.messages,
            [memberType]: {
              ...(event.emailPreferences?.messages as any)?.[memberType],
              [emailType]: row.message
            }
          }
        }
      }
    });
  }

  removeEmailMessageRow(row: { type: string, message: string }) {
    const {event} = this.state || {};
    const [memberType, emailType] = row.type.split('+');
    this.setState({
      event: {
        ...event,
        emailPreferences: {
          ...event.emailPreferences,
          messages: {
            ...event.emailPreferences?.messages,
            [memberType]: {
              ...(event.emailPreferences?.messages as any)?.[memberType],
              [emailType]: undefined
            }
          }
        }
      }
    });
  }

  getEmailMessagesRows() {
    const {event} = this.state || {};
    if (event) {
      return Object.keys(event.emailPreferences?.messages || {})
          .map(memberType => Object.keys((event.emailPreferences?.messages as any)[memberType] || {})
              .map(emailType => ({
                type: `${memberType}+${emailType}`,
                message: (event.emailPreferences?.messages as any)[memberType][emailType]
              })))
          .reduce((t, c) => t.concat(c), [])
          .filter((obj: any) => obj.message !== undefined);
    } else {
      return [];
    }
  }

  render() {
    const {classes, session: {settings, orgId, account, projectLeaderTypes}, disableRegistration} = this.props,
        {
          event,
          counties,
          clubs,
          paymentAdjustments,
          region,
          projectTypes,
          costCenterCodeOptions,
          designationNumOptions
        } = this.state || {},
        stateId = (account as StateAdminAccount)?.stateId,
        countyId = (account as CountyAdminAccount)?.countyId,
        districtId = (account as DistrictAdminAccount)?.districtId;

    let paCounties = [
      {id: 'State', name: 'State Level'},
      {id: 'Regional', name: 'Regional Level'}
      // @ts-ignore
    ].concat(counties);
    //console.log("PA COUNTIES", paCounties);

    //console.log('settings', settings);
    //console.log('session', session);
    if (!event) {
      return <Spinner/>;
    }

    return (<FormData data={event} onChange={(e: any) =>
        this.onChange({...event, ...e})}>
      <Card>
        <CardHeader color="info" icon>
          <CardIcon color="info">
            <Assignment/>
          </CardIcon>
          <span style={{display: 'flex'}}>
            <h3 className={classes.cardIconTitle}>Editing {event.name ? `Event "${event.name}"` : 'New Event'}</h3>
            <Button color="primary" component={RouterLink}
                    simple to="/events">
              <ArrowLeft style={{margin: '0'}}/> Back To Events
            </Button>
          </span>
        </CardHeader>
        <CardBody>
          <NavPills tabs={[
            {
              tabButton: 'Event',
              tabContent: <GridContainer alignItems="center" style={{width: '100%', margin: 0}}>
                <GridItem xs={12}><h3>Event Information</h3></GridItem>
                <GridItem xs={12}>
                  <hr/>
                </GridItem>
                <GridItem xs={12} md={6}>
                  <CustomFormInput id="name" labelText="Event Name"/>
                  <CustomFroalaTextField id="desc" labelText="Event Description"/>
                </GridItem>
                <GridItem xs={12} md={6}>
                  <ImageFormInput id="imageUrl" labelText="Event Image" altId="imageAlt" maxWidth={250}/>
                </GridItem>
                <br/>

                <GridItem xs={12}><h4>When:</h4></GridItem>
                <GridItem xs={12} sm={2}>
                  <SingleCheckbox id="isAllDay"
                                  labelText="Is All Day"/>
                </GridItem>
                <GridItem xs={12} sm={10}>
                  {event.isAllDay
                      ? <DateRangeFormInput startDateId="startsAt" endDateId="endsAt"/>
                      : <TimeRangeSelect startTimeId="startsAt" endTimeId="endsAt"/>}
                </GridItem>

                <GridItem xs={12}><h4>Where:</h4></GridItem>
                <GridItem xs={12} md={6}>
                  <CustomFormInput id="location" labelText="Location"/>
                </GridItem>
                <GridItem xs={12} md={6}>
                  {(settings.hideEventCountyRegion && account?.accountType === 'CountyAdmin') ? '' :
                      <FormData data={this.state} onChange={this.setState.bind(this)}>
                        <CustomSelectFormInput id="region" label="Region"
                                               options={[
                                                 ...(stateId ? ['State-Wide'] : []),
                                                 ...(countyId ? [...(settings.allowCountyStateWide ? ['State-Wide'] : []),
                                                   'County-Wide', 'Multi-Club'] : []),
                                                 ...(!projectLeaderTypes ? ['Multi-County'] : []),
                                               ]}/>
                      </FormData>}

                  {region === 'Multi-County' ?
                      <SelectMultiple
                          label={'Counties'}
                          options={counties.map(c =>
                              ({name: c.name, id: c.id}))}
                          value={event.countyIds}
                          fullWidth={true}
                          onChange={(value: any) => this.findClubs(value)}/> : ''}

                  {region === 'Multi-Club' ?
                      <MultiSelect id="clubIds" labelText="Clubs" fullWidth
                                   options={clubs.map(c =>
                                       ({name: c.name, id: c.id}))}/> : ''}
                </GridItem>

                <GridItem xs={12}><h4>Contact Info:</h4></GridItem>
                <GridItem xs={12} sm={6} md={4}>
                  <CustomFormInput id="contactName" labelText="Contact Name"/>
                </GridItem>
                <GridItem xs={12} sm={6} md={4}>
                  <CustomFormInput id="contactPhone" labelText="Contact Phone"/>
                </GridItem>
                <GridItem xs={12} sm={6} md={4}>
                  <CustomFormInput id="contactEmail" labelText="Contact Email"/>
                </GridItem>

                <GridItem xs={12}><h3>Registration Information</h3></GridItem>
                <GridItem xs={12}>
                  <hr/>
                </GridItem>
                <GridItem xs={12} md={6}>
                  {settings.state.enableEventRegistration && !disableRegistration
                      ? <SingleCheckbox id="regRequired"
                                        labelText="Registration is Required"/>
                      : ''}
                </GridItem>


                {event.regRequired ? <>
                  {settings.enrollmentsEnabled && event.regRequired ? <GridItem xs={12} md={6}>
                    <SingleCheckbox id="enrollmentRequired"
                                    labelText="Enrollment Required for Event"/>
                  </GridItem> : ''}
                  <GridItem xs={12} md={6}>
                    <SingleCheckbox id="allowVolunteerReg"
                                    labelText="Allow Volunteer Registration"/>
                  </GridItem>
                  <GridItem xs={12} md={6} style={{display: 'flex', alignItems: 'center'}}>
                    <SingleCheckbox id="allowExternalRegistration"
                                    labelText="Allow participants without a ZSuite account to Register"/>
                    <HelpModal>You will get a link once you publish the event to give to Non-ZSuite
                      participants to
                      register.</HelpModal>
                  </GridItem>
                  <GridItem xs={12}><h4>Entry Fee:</h4></GridItem>
                  <GridItem xs={12} md={4}>
                    <CustomFormInput id="fee" labelText="Fee" type={"number"}
                                     helperText={"Fee must be a single value number"}
                                     startAdornment={<InputAdornment
                                         position="start">$</InputAdornment>}/>
                  </GridItem>
                  {!!event.fee ? <GridItem xs={12} md={2}>
                    <SingleCheckbox id="onlinePaymentOnly" labelText="Online Payments Only"/>
                  </GridItem> : ''}
                  {!!event.fee ? <GridItem xs={12} md={2}>
                    <WithFormProp prop="properties">
                      <SingleCheckbox id="disableOnlinePayments"
                                      labelText="Disable Online Payments"/>
                    </WithFormProp>

                  </GridItem> : ''}
                  {/*{!!event.fee && (settings?.state?.stateId === 'US-MT' || settings?.state?.stateId === 'ex-st') ?*/}
                  {/*    <GridItem xs={12} md={2}>*/}
                  {/*      <WithFormProp prop="properties">*/}
                  {/*        <CustomSelectFormInput id="mtEventPaymentMethod"*/}
                  {/*                               label="Select Your Payment Method"*/}
                  {/*                               options={[*/}
                  {/*                                 {id: "nelnet", name: 'Nelnet'},*/}
                  {/*                                 {id: "stripe_connect", name: "Stripe"}*/}
                  {/*                               ]}/>*/}
                  {/*      </WithFormProp>*/}

                  {/*    </GridItem>*/}
                  {/*    : ''}*/}


                  {(!!event.fee && settings?.state?.stateId === 'US-AR') ?
                      <GridItem xs={12} md={2}>
                        <WithFormProp prop="properties">
                          <CustomSelectFormInput id="costCenter"
                                                 label="Select Your Cost Center"
                                                 options={costCenterCodeOptions}/>
                        </WithFormProp>

                      </GridItem> : ''}

                  {(!!event.fee && settings?.state?.stateId === 'US-AR' && event?.properties?.costCenter) ?
                      <GridItem xs={12} md={2}>
                        <WithFormProp prop="properties">
                          <CustomSelectFormInput id="designationNum"
                                                 label="Select Your Designation Num"
                                                 options={designationNumOptions}/>
                        </WithFormProp>

                      </GridItem> : ''}

                  {(stateId === 'US-OK' && account?.accountType === 'StateAdmin' && settings.showEnablePaymentsForStateAdmin) ?
                      <GridItem xs={12} md={2}>
                        <WithFormProp prop="properties">
                          <SingleCheckbox id="enableOnlinePayments"
                                          labelText="Enable Online Payments"/>
                        </WithFormProp>

                      </GridItem> : ''}

                  {(!!event.fee && stateId === 'US-ID' && account?.accountType === 'StateAdmin' && settings.showPaymentDetailCode) ?
                      <GridItem xs={12} md={2}>
                        <WithFormProp prop="properties">
                          <CustomSelectFormInput id="paymentDetailCode" noClear
                                                 label="Detail Code"
                                                 options={
                                                   [
                                                     {id: null, label: "None"},
                                                     {id: "BQTC", label: "BQTC - Teen Conference"},
                                                     {id: "BQKC", label: "BQKC - Know Your Government"},
                                                     {id: "BQNC", label: "BQNC - National Confernece"},
                                                     {id: "BQLJ", label: "BQLJ - Livestock Judging"},
                                                     {id: "BQAC", label: "BQAC - 4-H Ambassadors"},
                                                     {id: "BQSS", label: "BQSS - Shooting Sports"}
                                                   ]
                                                 }/>
                        </WithFormProp>

                      </GridItem> : ''}

                  {(!!event.fee && settings.showHostCountyPaymentSelection) ?
                      <GridItem xs={12} md={2}>
                        <WithFormProp prop="properties">
                          <CustomSelectFormInput id="hostPaymentCounty" noClear
                                                 label="County that payments will go to"
                                                 options={counties.sort((a, b) => ((a as any).name).localeCompare((b as any).name))}/>
                        </WithFormProp>

                      </GridItem> : ''}

                  <GridItem xs={12}><h4>When:</h4></GridItem>
                  <GridItem xs={12}>
                    <TimeRangeSelect
                        startTimeId="registrationOpen" startLabel="Registration Open"
                        endTimeId="registrationClose" endLabel="Registration Closed"/>
                  </GridItem>
                  <GridItem xs={12}><h4>Limit Registrations:</h4></GridItem>
                  <GridItem xs={12} md={6}>
                    <CustomFormInput id="attendeeRegCap"
                                     labelText={`Cap on # of ${pluralize(settings.state.clubMemberLabel)}`}/>
                  </GridItem>
                  {event.allowVolunteerReg ? <GridItem xs={12} md={6}>
                    <CustomFormInput id="volunteerRegCap"
                                     labelText={`Cap on # of ${pluralize(settings.state.volunteerLabel)}`}/>
                  </GridItem> : ''}
                  <GridItem xs={12}/>
                  <GridItem xs={12} md={6}>
                    <CustomFormInput id="minAge" labelText="Minimum age of attendees"/>
                  </GridItem>
                  <GridItem md={6}>
                    {event.minAge ? <DateTimeFormInput dateFormat id="minAgeAsOf"
                                                       labelText="As of"/> : ''}
                  </GridItem>
                  <GridItem xs={12} md={6}>
                    <CustomFormInput id="maxAge" labelText="Maximum age of attendees"/>
                  </GridItem>
                  <GridItem md={6}>
                    {event.maxAge ? <DateTimeFormInput dateFormat id="maxAgeAsOf"
                                                       labelText="As of"/> : ''}
                  </GridItem>
                  <GridItem xs={12} md={6}>
                    <CustomSelectFormInput
                        id="minGrade" labelText="Minimum grade of attendees"
                        options={['Any', 'Kindergarten', '1st', '2nd', '3rd', '4th', '5th', '6th', '7th', '8th', '9th', '10th', '11th', '12th',
                          'Post HS']}/>
                  </GridItem>
                  <GridItem xs={12} md={6}>
                    <CustomSelectFormInput
                        id="maxGrade" labelText="Maximum grade of attendees"
                        options={['Any', 'Kindergarten', '1st', '2nd', '3rd', '4th', '5th', '6th', '7th', '8th', '9th', '10th', '11th', '12th',
                          'Post HS']}/>
                  </GridItem>
                  {(settings.enrollmentsEnabled && (event.enrollmentRequired || projectLeaderTypes?.length) && event.enrollmentRequired) ?
                      <GridItem xs={12} md={6}>
                        <MultiSelect id="projectTypes" labelText="Limit Project Types"
                                     fullWidth
                                     options={projectTypes}/>
                      </GridItem> : ''}
                </> : ''}

                <GridItem xs={12}><h3>Additional Information</h3></GridItem>
                <GridItem xs={12}>
                  <hr/>
                </GridItem>
                {event.regRequired ?
                    <GridItem xs={12} md={6}>
                      <SingleCheckbox id="approvalRequired"
                                      labelText="Manual Approval Required"/>
                    </GridItem> : ''}
                {event.approvalRequired ? <GridItem xs={12} md={6}>
                  <SingleCheckbox id="payAfterApproval" labelText="Pay After Approval"/>
                </GridItem> : ''}

                {event.regRequired && settings?.state?.stateId === 'US-IL' && account?.accountType === 'StateAdmin' ?
                    <WithFormProp prop="properties">
                      <GridItem xs={12} md={6}>
                        <SingleCheckbox id="disableHouseholdRegistration"
                                        labelText="Disable Household Event Registration"/>
                      </GridItem>
                    </WithFormProp>
                    : ''}

                {event.regRequired && settings?.state?.stateId !== 'US-IL' && account?.accountType === 'StateAdmin'
                && settings.showDisableHouseholdEventRegistration ?
                    <WithFormProp prop="properties">
                      <GridItem xs={12} md={6}>
                        <SingleCheckbox id="disableHouseholdRegistration"
                                        labelText="Disable Household Event Registration"/>
                      </GridItem>
                    </WithFormProp>
                    : ''}


                {/*Disable County Approvals Check Box*/}
                {event.regRequired && account?.accountType === "StateAdmin" ? <GridItem xs={12} md={6}
                                                                                        style={{
                                                                                          display: 'flex',
                                                                                          alignItems: 'center'
                                                                                        }}>
                  <SingleCheckbox id="disableCountyApprovals"
                                  labelText="Disable County/District Admin Approval"/>
                  {settings.allowEventEditing ? <HelpModal>This will disable the ability for
                        County/District Admins to approve registrations and edit payment
                        fields.</HelpModal>
                      : <HelpModal>This will disable the ability for County/District Admins to
                        approve/edit registrations.</HelpModal>}
                </GridItem> : ""}

                {!!event.fee ? <GridItem xs={12} md={6} style={{display: 'flex'}}>
                  <SingleCheckbox id="sendEmailAfterPayment"
                                  labelText="Send Registration Email After Payment"/>
                  <HelpModal>This will send the Email Message after the registration fee has been
                    paid</HelpModal>
                </GridItem> : ''}

                <GridItem xs={12}/>
                {orgId === '4h' ? <GridItem xs={12} md={6}>
                  <WithFormProp prop="properties">
                    <MultiSelect
                        id="es237PrimaryTypes" labelText="ES237 Type"
                        options={[
                          {id: '', name: 'None'}, {
                            id: 'specialInterest',
                            name: 'Special Interest/Short-Term'
                          }, {
                            id: 'overnightCamping',
                            name: 'Overnight Camping'
                          }, {
                            id: 'dayCamping',
                            name: 'Day Camping'
                          }, {
                            id: 'enrichmentProgram',
                            name: 'Enrichment Program'
                          }, {
                            id: 'individualStudyMentoring',
                            name: 'Individual Study/Mentoring/Family Learning'
                          }, {
                            id: 'afterSchoolProgram',
                            name: 'After-School Programs Using 4-H Curricula/Staff Training'
                          }, {
                            id: 'instructionTvVideo',
                            name: 'Instructional TV/Video/Web Programs'
                          }
                        ]}/>
                  </WithFormProp>
                </GridItem> : ''}
                <GridItem xs={12} md={6}>
                  <CustomFormInput id="externalRegistrationLink"
                                   labelText="External Registration Link (Outside of ZSuite)"
                                   helperText="ONLY INSERT A LINK IN HERE IF REGISTRATION IS HANDLED OUTSIDE OF ZSUITE"/>
                </GridItem>
                <GridItem xs={12} md={6}>
                  {event.regRequired ?
                      (settings.state.eventItemCodes?.length
                              ? <CustomSelectFormInput id="eventIndex" labelText="Event Item Code"
                                                       options={
                                                         settings.state.eventItemCodes.map(({code, desc}: {
                                                           code: string,
                                                           desc: string
                                                         }) =>
                                                             ({
                                                               id: code,
                                                               name: `${code} - ${desc}`
                                                             }))}/>
                              :

                              //CHECKING IF EVENT INDEX IS SUPPOSED TO SHOW OR NOT
                              settings.hideEventIndex !== true ?

                                  //IF EVENT INDEX IS SUPPOSED TO SHOW CHECKIING IF THE STATE ID IS UTAH
                                  settings.state.stateId === 'US-UT' ?

                                      //IF THE STATE ID IS UTAH SHOW ADDED TEXT EXPLAINING WHAT THE INDEX IS FOR
                                      <div>
                                        <CustomFormInput id="eventIndex" labelText="Event Index"/>
                                        <i>
                                          Index to which funds are to be placed. If this is a free
                                          event
                                          enter “Free
                                        </i>
                                      </div> :

                                      //IF THE STATE ID IS NOT UTAH JUST SHOW THE FIELD
                                      <CustomFormInput id="eventIndex" labelText="Event Index"/>

                                  //IF EVENT INDEX IS SUPPOSED TO BE HIDDEN DO NOT SHOW ANYTHING EXPLAINED ABOVE
                                  : ''
                      )
                      : ''}
                </GridItem>
                <GridItem xs={12} md={6}/>

                {((settings?.state?.stateId === 'US-IL' && account?.accountType === 'StateAdmin') || settings.showContEdForEvents)
                    ? <WithFormProp prop={"properties"}>
                      <GridItem xs={6}>
                        <SingleCheckbox id="isContinuingEd" labelText="Is this event Continuing Ed?"/>
                      </GridItem>
                      <GridItem xs={6}>
                        {(
                            (
                                (settings?.state?.stateId === 'US-IL' && account?.accountType === 'StateAdmin') || settings.showContEdForEvents
                            ) && event?.properties?.isContinuingEd
                        )
                            ? <WithFormProp prop={"properties"}>
                              <CustomFormInput id="continuingEdHours" type={"number"}
                                               labelText="Hours of continuing Ed"/>
                            </WithFormProp>
                            : ''}
                      </GridItem>

                    </WithFormProp>
                    : ''}

                {/*{(settings?.state?.stateId === 'US-IL' && event?.properties?.isContinuingEd)*/}
                {/*    ? <WithFormProp prop={"properties"}>*/}
                {/*      <GridItem xs={12}>*/}
                {/*        <CustomFormInput id="continuingEdHours" type={"number"}*/}
                {/*                         labelText="Hours of continuing Ed"/>*/}
                {/*      </GridItem>*/}
                {/*    </WithFormProp>*/}
                {/*    : ''}*/}


                {/*PENNSYLVANIA SPECIFIC FIELDS FOR EVENT REGISTRATION*/}
                <WithFormProp prop="properties">
                  <GridItem md={12}>
                    {
                        settings?.state?.stateId === 'US-PA' && <div>
                          <GridContainer>
                            <GridItem md={12}>
                              <h5>Financial Information: </h5>
                              <CustomFormInput id="eventIODescription"
                                               labelText="Event IO Description"/>
                              <CustomFormInput id="eventCostCenter" labelText="Event Cost Center"/>
                              <CustomFormInput id="eventEndingBalance"
                                               labelText="Event Ending Balance IO Description: Account
                                                           where any fees collected for the event should be deposited
                                                           at event closing"/>
                              <CustomFormInput id="endingBalanceIONum"
                                               labelText="Event Ending Balance IO Number"/>
                            </GridItem>

                            <GridItem md={6}>
                              <CustomSelectFormInput id="financialHost"
                                                     fullWidth
                                                     label="Event Financial Host County/Office (SIMBA)"
                                                     options={
                                                       paCounties.map(c => (
                                                           {name: c.name, id: c.id}
                                                       ))
                                                     }/>
                            </GridItem>

                            <GridItem md={6}>
                              <CustomSelectFormInput id="eventFormat"
                                                     fullWidth
                                                     label="Event Format Selection"
                                                     options={[
                                                       {name: "In-Person", id: "inPerson"},
                                                       {name: "Virtual | Live", id: "virtual"},
                                                       {
                                                         name: "On-Demand | Recorded",
                                                         id: "recorded"
                                                       },
                                                       {
                                                         name: "Hybrid | Choice of In-Person or Virtual Attendee",
                                                         id: "hybrid"
                                                       },
                                                       {
                                                         name: "Blended | Combines In-Person w/ Online Component",
                                                         id: "blended"
                                                       },
                                                       {name: "Non-Event", id: "nonEvent"}
                                                     ]}/>
                            </GridItem>


                            <GridItem md={6}>
                              <CustomFormInput id="leaderBaseCounty"
                                               labelText="Event Leader Base County"/>
                            </GridItem>
                            <GridItem md={6}>
                              <CustomFormInput id="leaderArea" labelText="Event Leader Area/UP"/>
                            </GridItem>
                            <GridItem md={6}>
                              <CustomFormInput id="venueCounty" labelText="Venue/Host County"/>
                            </GridItem>
                            <GridItem md={6}>
                              <CustomFormInput id="venueArea" labelText="Venue/Host Area/UP"/>
                            </GridItem>

                            <GridItem md={6}>
                              <CustomSelectFormInput id="eventTags"
                                                     fullWidth
                                                     label="Event Tags"
                                                     options={[
                                                       {
                                                         name: "Banquet/Meal Registration",
                                                         id: "banquet"
                                                       },
                                                       {name: "Camp", id: "camp"},
                                                       {name: "Conference", id: "conference"},
                                                       {
                                                         name: "Contest Registration",
                                                         id: "contestRegistration"
                                                       },
                                                       {
                                                         name: "Education Workshop",
                                                         id: "educationalWorkshop"
                                                       },
                                                       {
                                                         name: "Non-Event (ex: County Deadline; Holiday/Office Colsure; Fundraiser Order Collections' Animal Designations/Tagging Requests",
                                                         id: "nonEvent"
                                                       },
                                                       {name: "Webinar", id: "webinar"},
                                                       {name: "Other", id: "other"}
                                                     ]}/>
                            </GridItem>
                            <GridItem md={6}>
                              <CustomSelectFormInput id="sessionRecordingChoise"
                                                     fullWidth
                                                     label="Status of Session Recording"
                                                     options={[
                                                       {
                                                         name: "Not yet Available for distribution",
                                                         id: "notAvailable"
                                                       },
                                                       {
                                                         name: "Distributed to registrants",
                                                         id: "distributed"
                                                       },
                                                       {
                                                         name: "Will not be available",
                                                         id: "willNotBeAvailable"
                                                       },
                                                       {
                                                         name: "Not Applicable (In-Person)",
                                                         id: "notApplicable"
                                                       },
                                                     ]}/>
                            </GridItem>
                            <GridItem md={6}>
                              <CustomFormInput id="cancellationPolicyNotes"
                                               labelText="Cancellation Policy Notes"/>
                            </GridItem>
                            <GridItem md={6}>
                              <CustomFormInput id="customerServiceNotes"
                                               labelText="Customer Service Notes"/>
                            </GridItem>
                            <GridItem md={3}>
                              <SingleCheckbox id="isGroupEnrollment"
                                              labelText="Have these registrations also been filed as a group enrollment?"/>
                            </GridItem>
                            <GridItem md={3}>
                              <SingleCheckbox id="allowWalkIns"
                                              labelText="Will this event allow Walk-In Registrations?"/>
                            </GridItem>
                            <GridItem md={3}>
                              <SingleCheckbox id="promptedOnWebsite"
                                              labelText="Should this event be prompted on county website?"/>
                            </GridItem>
                            <GridItem md={3}>
                              <SingleCheckbox id="act48Credits"
                                              labelText="This event will offer Act 48 credits"/>
                            </GridItem>


                            <GridItem md={6}>
                              <CustomSelectFormInput id="teamPickList"
                                                     fullWidth
                                                     label="Program Team Pick List"
                                                     options={[
                                                       {
                                                         name: "4-H: Positive Youth Development",
                                                         id: "positiveYouthDevelopment"
                                                       },
                                                       {name: "4-H: STEM", id: "STEM"},
                                                       {
                                                         name: "4-H: Volunteer Management and Development",
                                                         id: "volunteerManagementDevelopment"
                                                       },
                                                       {
                                                         name: "Non 4-H: Agronomy & Natural Resources - Water Quality and Quantity",
                                                         id: "naturalResources"
                                                       },
                                                       {
                                                         name: "Non 4-H: Animal systems Unit - Dairy",
                                                         id: "animalSystemUnitDairy"
                                                       },
                                                       {
                                                         name: "Non 4-H: Animal Systems Unit - Poultry",
                                                         id: "animalSystemsUnitPoultry"
                                                       },
                                                       {
                                                         name: "Non 4-H: Energy, Business & Community Vitality Unit - Leadership & Community Vitality",
                                                         id: "leadership"
                                                       },
                                                       {
                                                         name: "Non 4-H: Food, Families & Health Unit - Health and Wellness",
                                                         id: "food"
                                                       },
                                                       {
                                                         name: "Non 4-H: Horticulture Unit - Master Gardener",
                                                         id: "masterGardener"
                                                       },
                                                       {
                                                         name: "Non 4-H: N/A or Non-educational",
                                                         id: "nonEducational"
                                                       },
                                                       {name: "Other", id: "Other"},
                                                     ]}/>
                            </GridItem>
                            <GridItem md={6}>
                              <CustomSelectFormInput id="stateManager"
                                                     fullWidth
                                                     label="State Admin Event Manager Assignment"
                                                     options={[
                                                       {
                                                         name: "Stacie Hritz - sxd297@psu.edu",
                                                         id: "stacieHritz"
                                                       },
                                                     ]}/>
                            </GridItem>
                            <GridItem md={12}>
                              <CustomFroalaTextField id="eventAgenda"
                                                     labelText="Event Detailed Agenda"/>
                            </GridItem>


                            {/*<GridItem md={12}>*/}
                            {/*  <TableModule title="Speaker Information"*/}
                            {/*               singular="Speaker Information"*/}
                            {/*               tableClass="table-responsive"*/}
                            {/*               columns={[*/}
                            {/*                   {label: 'First Name', key: 'firstName', type: 'text', editable: true},*/}
                            {/*                   {label: 'Last Name', key: 'lastName', type: 'text', editable: true},*/}
                            {/*                   {label: 'Email', key: 'email', type: 'text', editable: true},*/}
                            {/*                   {label: 'Phone', key: 'phone', type: 'text', editable: true},*/}
                            {/*                   {label: 'Company', key: 'company', type: 'text', editable: true},*/}

                            {/*               ]}*/}
                            {/*               basePath="/feed_records"/>*/}

                            {/*</GridItem>*/}
                          </GridContainer>


                        </div>
                    }
                  </GridItem>
                </WithFormProp>
                {/*END OF PENNSYLVANIA SPECIFIC FIELDS FOR EVENT REGISTRATION*/}

                <GridItem xs={12} md={6}>
                  <FileAttachmentsFormInput id="fileAttachments"
                                            labelText="File Attachments"/>
                </GridItem>
                <GridItem xs={12}>
                  <div style={{margin: '1rem 0'}}>
                    Status: <Badge
                      color={
                        event.status === 'Published' ? 'success' :
                            event.status === 'Hidden' ? 'yellow'
                                : 'danger'
                        // event.status === 'Published'
                        // ? 'success' : 'danger'
                      }>{event.status}</Badge>
                  </div>
                </GridItem>
              </GridContainer>
            },
            ...(event.regRequired ? [{
              tabButton: 'Registration Form',
              tabContent: <>
                <h3>Participant Registration Fields
                  <Button justIcon simple round color={"primary"} size="sm" component="a"
                          style={{margin: '0 0 4px 0'}}
                          href={"https://zsuite.s3.us-east-2.amazonaws.com/static/Help/ZSuiteHelp/Events-RegistrationForm.html"}
                          target={"_blank"}>
                    <HelpOutline/>
                  </Button>
                  <p style={{fontSize: 12}}>
                    (Click the help button to go to a help doc to learn how to set up registration fields)
                  </p>
                </h3>


                <CustomFormEditorInput showPaidOptions id="participantRegFields"
                                       getMetaFields={(field) => (['option', 'paid-option'].indexOf(field.type) > -1) ? [{
                                         key: 'limitNumberOfSelections',
                                         title: 'Limit number of selections',
                                         type: 'text', validator: 'numeric',
                                         isRequired: false,
                                       } as FormField] : []}/>

                <hr style={{margin: '4rem 0'}}/>

                {event.allowVolunteerReg ? <div>
                  <Button simple color="primary" onClick={async () => {
                    if (event.volunteerRegFields?.length) {
                      await confirmModal('Confirm Copy Fields',
                          'Are you sure? Doing this will override any existing fields');
                    }
                    this.setState({
                      event: {
                        ...event,
                        volunteerRegFields: (event.participantRegFields || [])
                      }
                    });
                  }} startIcon={<FileCopy/>}> Copy Participant Fields</Button>
                  <h3>
                    Volunteer Registration Fields
                    <Button justIcon simple round color={"primary"} size="sm" component="a"
                            style={{margin: '0 0 4px 0'}}
                            href={"https://zsuite.s3.us-east-2.amazonaws.com/static/Help/ZSuiteHelp/Events-RegistrationForm.html"}
                            target={"_blank"}>
                      <HelpOutline/>
                    </Button>
                    <p style={{fontSize: 12}}>
                      (Click the help button to go to a help doc to learn how to set up registration fields)
                    </p>
                  </h3>
                  <CustomFormEditorInput showPaidOptions id="volunteerRegFields"
                                         getMetaFields={(field) => (['option', 'paid-option'].indexOf(field.type) > -1) ? [{
                                           key: 'limitNumberOfSelections',
                                           title: 'Limit number of selections',
                                           type: 'text', validator: 'numeric',
                                           isRequired: false,
                                         } as FormField] : []}/>
                </div> : ''}
              </>
            },
              {
                tabButton: 'Registration Documents',
                tabContent: <>
                  <h3>Participant Documents and Waivers</h3>
                  <EditWaiversInput id="participantWaivers"
                                    confirmableWaivers={settings.state.enrollmentSteps.waivers.clubMember}
                                    addWaiverLabel="New Participation Document"
                  />

                  {
                    event.allowVolunteerReg ? <div>
                      <hr style={{margin: '4rem 0'}}/>

                      <Button simple color="primary" onClick={async () => {
                        if (event.volunteerRegFields?.length) {
                          await confirmModal('Confirm Copy Documents/Waivers',
                              'Are you sure? Doing this will override any existing fields');
                        }
                        this.setState({
                          event: {
                            ...event,
                            volunteerWaivers: (event.participantWaivers || [])
                          }
                        });
                      }} startIcon={<FileCopy/>}> Copy Participant Documents</Button>

                      <h3>Volunteer Documents and Waivers</h3>
                      <EditWaiversInput id="volunteerWaivers"
                                        confirmableWaivers={settings.state.enrollmentSteps.waivers.volunteer}
                                        addWaiverLabel="New Volunteer Document"
                      />
                    </div> : ''
                  }
                </>
              }] : []),
            ...(settings.paymentAdjustmentsEnabled && event.regRequired ? [{
              tabButton: 'Payment Adjustments',
              tabContent:
                  <PaymentAdjustmentTable
                      defaultCountyId={countyId}
                      value={paymentAdjustments}
                      onChange={(newPaymentAdjustments: PaymentAdjustment[]) => {
                        this.setState({paymentAdjustments: newPaymentAdjustments});
                      }}/>
            }] : []),
            {
              tabButton: 'Email Settings',
              tabContent:
                  <>
                    <h3>Email Preferences</h3>
                    <p><i>Add email addresses to receive emails about this event</i></p>
                    <Table tableHead={['', '']} tableData={[
                      [<span>Registration Submitted<HelpModal>These emails will be notified whenever a member or volunteer submits a registration for the event</HelpModal></span>,
                        <List>
                          {(event.emailPreferences?.contactEmails?.['registrationSubmitted'] || []).map(email =>
                              <ListItemText><span
                                  style={{display: 'flex', alignItems: 'center'}}>{email}
                                <Tooltip title="Remove Email" placement="top"><Button
                                    color="danger"
                                    size="sm" simple
                                    justIcon
                                    onClick={() => this.removeEmailFromPreference(email, 'registrationSubmitted')}>
                          <Clear/>
                          </Button></Tooltip>
                        </span></ListItemText>)}
                        </List>,
                        <Button color="primary" type="button" onClick={() =>
                            runModalForm(() => <CustomFormInput id="email"
                                                                inputProps={{placeholder: 'test@example.com'}}/>, {
                              title: 'Add Email',
                              fullWidth: true, size: 'sm',
                              submitLabel: 'Add Email',
                              onSubmit: ({email}: { email: string }) => {
                                if (!validateEmail(email)) {
                                  throw new Error('Not a valid email');
                                }
                                this.addEmailToPreference(email, 'registrationSubmitted');
                              }
                            })}><Add/> Add Email</Button>]
                    ]}/>
                    <h3>Additional Email Messages</h3>
                    <p><i>Attach additional messages to emails that go out to registered participants</i>
                    </p>
                    <TableEdit
                        title="Additional Message"
                        noGridStyling
                        onSaveRow={this.saveEmailMessageRow.bind(this)}
                        onRemoveRow={this.removeEmailMessageRow.bind(this)}
                        rows={this.getEmailMessagesRows()}
                        cols={[
                          {
                            key: 'type',
                            label: 'Email Type',
                            xs: 12, md: 6,
                            editable: true,
                            required: true,
                            type: 'enum',
                            values: (row: any, {rows, newRow}: { rows: any[], newRow: boolean }) => {
                              const allRows = [
                                {value: 'clubMember', label: 'Club Member'},
                                {value: 'volunteer', label: 'Volunteer'},
                                ...(settings.separateNewVolunteers ? [{
                                  value: 'newVolunteer',
                                  label: 'New Volunteer'
                                }] : [])
                              ].map(memberType => [
                                ...(event.regRequired ? [{
                                  value: 'registrationSubmitted',
                                  label: 'Registration Submitted'
                                }] : []),
                                ...(event.payAfterApproval ? [{
                                  value: 'readyToPay',
                                  label: 'Registration has been approved and participant needs to pay'
                                }] : [])
                              ].map(emailType => ({
                                value: `${memberType.value}+${emailType.value}`,
                                label: `${memberType.label} - ${emailType.label}`
                              }))).reduce((t, c) => t.concat(c), []);
                              if (newRow) {
                                return allRows.filter(row => !(rows.indexOf((r: any) => r.type === row.value)));
                              } else {
                                return allRows.filter(r => r.value === row.type || !rows.map((rs: any) => rs.type).includes(r.value));
                              }
                            }
                          },
                          {
                            key: 'message',
                            label: 'Additional Message',
                            xs: 12,
                            editable: true,
                            required: true,
                            format: (value: any) => <div className="fr-view"
                                                         dangerouslySetInnerHTML={{__html: value}}/>,
                            formatEditor: (value: any, _: any, setValue: any) =>
                                <FormData data={{value}}
                                          onChange={(newData: any) => setValue(newData.value)}>
                                  <CustomFroalaTextField id="value"/>
                                </FormData>
                          }
                        ]}/>
                  </>
            },
            ...((settings.showPostEventDocs && event.id) ? [{
              tabButton: 'Post Event Documents',
              tabContent:
                  <FormData data={event.properties} onChange={(newData: any) => {
                    const newEvent = {...event, properties: {...event.properties, ...newData}};
                    this.setState({event: newEvent});
                    return this.props.session.backendClient.updateEvent({}, newEvent);
                  }}>
                    <h3>Post Event Documents</h3>
                    <FileAttachmentsFormInput id="postEventDocs"/>
                  </FormData>
            }] : []),
          ]}/>
          {
            this.renderSaveButtons()
          }
        </CardBody>
      </Card>
    </FormData>)
        ;
  }
}

export default withRouter(withSession(withStyles(dashboardStyle as any)(EventEditor)));

export function formatEventDate(event: Event): string {
  if (event.isAllDay) {
    return moment(event.startsAt).format('MMM Do') + ' (All Day)';
  } else {
    return formatDateRange(event.startsAt, event.endsAt);
  }
}

/**
 * This takes a registration form and limited options (how many participants have selected one of the limited options)
 * and returns a new form with the proper options disabled
 * @param form - the event registration form
 * @param limitedOptions - the limited options from the getEventLimitedOptions endpoint
 */
export function applyEventLimitedOptions(form: CustomForm, limitedOptions: EventLimitedOption[]): CustomForm {
  const optionMap: Record<string, EventLimitedOption[]> = limitedOptions.reduce((obj, op) => ({
    ...obj, [op.key]: obj[op.key] ? obj[op.key].concat([op]) : [op]
  }), {} as Record<string, EventLimitedOption[]>);
  return form.map(category => ({
    ...category, children: category?.children?.map(formField => {
      if (['select', 'multi-select'].indexOf(formField.type) > -1 && optionMap[formField.key]) {
        return {
          ...formField, children: formField.children.map(option => {
            const limitedOption = optionMap[formField.key].find(op => op.value === option.title);
            //console.log("OPTION", option);
            if (limitedOption) {
              return {
                ...option,
                optionLabel: `${option.title} (${Math.max(0, limitedOption.limit - limitedOption.selectionCount)} remaining) 
                                    ${option.paidOptionAmount ? ' - ' + formatMoney(option.paidOptionAmount) : ''}`,
                disabled: !limitedOption.selected && limitedOption.selectionCount >= limitedOption.limit
              };
            } else {
              return option;
            }
          })
        };
      } else {
        return formField;
      }
    })
  }));
}